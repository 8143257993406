import { ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslocoModule } from "@ngneat/transloco";
import { TuiButtonModule, TuiHostedDropdownModule } from "@taiga-ui/core";
import { RouterModule } from "@angular/router";
import { ActionButtonComponent } from "../../../ui/button/action-button.component";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "dev-portal-burger-menu",
  standalone: true,
  templateUrl: "./burger-menu.component.html",
  imports: [
    CommonModule,
    TuiHostedDropdownModule,
    TranslocoModule,
    RouterModule,
    TuiButtonModule,
    ActionButtonComponent
  ]
})
export class BurgerMenuComponent {
  isOpen = signal<boolean>(false);

  @Input({ required: true }) isLoggedIn!: boolean;
  @Input({ required: true }) canUpgrade!: boolean;

  close() {
    this.isOpen.set(false);
  }
}
