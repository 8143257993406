<dev-portal-action-button
  (click)="isOpen.set(true)"
  class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  icon="/assets/icons/burger-menu.svg#img"
></dev-portal-action-button>

<div
  class="bg-day-bg-bg-main dark:bg-night-bg-bg-main 1inch-text-regular-16 fixed inset-0 z-[9999] flex flex-1 flex-col gap-12 p-4 transition-transform duration-300 ease-in-out"
  [ngClass]="!isOpen() ? 'translate-x-full' : 'translate-x-0'"
>
  <div class="flex items-center justify-between">
    <svg class="tablet:hidden h-8 w-32">
      <use xlink:href="/assets/logos/logo-mobile.svg#img" />
    </svg>
    <svg class="tablet:block desktop:hidden hidden h-12 w-44">
      <use xlink:href="/assets/logos/logo-tablet.svg#img" />
    </svg>
    <dev-portal-action-button
      (click)="close()"
      class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
      icon="/assets/icons/close.svg#img"
    ></dev-portal-action-button>
  </div>
  <div class="relative flex flex-col justify-start gap-6 px-2">
    <ng-container *ngTemplateOutlet="isLoggedIn ? loggedInTemplate : loggedOut"></ng-container>
    @if (canUpgrade && isLoggedIn) {
      <a
        tuiButton
        appearance="brand-primary"
        (click)="close()"
        [size]="'m'"
        routerLink="/pricing"
        routerLinkActive="text-day-static-brand dark:text-night-static-brand"
        [routerLinkActiveOptions]="{ exact: true }"
        data-test-id="navigationPricing"
        class="tablet:w-[200px] mt-6"
      >
        {{ "navigation.items.pricing" | transloco }}
      </a>
    }
  </div>
</div>

<ng-template #loggedInTemplate>
  <a
    routerLink="/dashboard"
    data-test-id="navigationDashboard"
    (click)="close()"
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
    class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  >
    {{ "navigation.items.dashboard" | transloco }}
  </a>

  <a
    routerLink="/applications"
    (click)="close()"
    data-test-id="navigationApplications"
    routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
    class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  >
    {{ "navigation.items.applications" | transloco }}
  </a>

  <a
    data-test-id="navigationStatistics"
    routerLink="/statistics"
    (click)="close()"
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
    class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  >
    {{ "navigation.items.stats" | transloco }}
  </a>

  <a
    routerLink="/documentation"
    (click)="close()"
    data-test-id="navigationDocumentation"
    routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
    class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  >
    {{ "navigation.items.docs" | transloco }}
  </a>
  <a
    routerLink="/pricing"
    (click)="close()"
    data-test-id="navigationPricing"
    routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
    class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  >
    {{ "navigation.items.pricing" | transloco }}
  </a>

  <a
    routerLink="/support"
    (click)="close()"
    data-test-id="navigationDocumentation"
    routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
    class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  >
    {{ "navigation.items.support" | transloco }}
  </a>
</ng-template>

<ng-template #loggedOut>
  <a
    routerLink="/support"
    (click)="close()"
    data-test-id="navigationDocumentation"
    routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
    class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  >
    {{ "navigation.items.support" | transloco }}
  </a>

  <a
    routerLink="/documentation"
    (click)="close()"
    data-test-id="navigationDocumentation"
    routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
    class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  >
    {{ "navigation.items.docs" | transloco }}
  </a>

  <a
    routerLink="/login"
    (click)="close()"
    data-test-id="navigationSignIn"
    routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
    class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
  >
    {{ "navigation.items.login" | transloco }}
  </a>
</ng-template>
