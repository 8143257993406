import { ChangeDetectionStrategy, Component } from "@angular/core";

import { TranslocoMarkupModule } from "ngx-transloco-markup";
import { environment } from "../../../environments/environment";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "dev-portal-footer",
  standalone: true,
  imports: [TranslocoMarkupModule],
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {
  legalUrls = environment.legal;
  currentYear = new Date().getFullYear();
}
