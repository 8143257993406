import { ChangeDetectionStrategy, Component, computed, HostBinding, inject, Input } from "@angular/core";

import { Router, RouterModule } from "@angular/router";
import { AuthService } from "../../domain/auth/auth.service";
import { TranslocoModule } from "@ngneat/transloco";
import { TuiButtonModule, TuiHostedDropdownModule } from "@taiga-ui/core";
import { ThemeSwitcherService, ThemeMode } from "../theme-switcher.service";
import { TuiCheckboxModule } from "@taiga-ui/kit";
import { NavigationSettingsComponent } from "./navigation-settings.component";
import { ActionButtonComponent } from "../../ui/button/action-button.component";
import { BurgerMenuComponent } from "./burger-menu/burger-menu.component";
import { ProfileService } from "../../domain/profile/profile.service";
import { toSignal } from "@angular/core/rxjs-interop";
import { I18nService } from "../../domain/i18n/i18n.service";
import { WalletConnectService } from "../../domain/wallet/wallet-connect.service";
import { SubscriptionService } from "../../domain/subscription/subscription.service";
import { ConsumptionPlan } from "@libs/shares/models/subscription.model";
import { hasRole, Role, Scopes } from "@libs/shares/models/auth.model";
import { UnleashToggleService } from "../../domain/unleash/unlease.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "dev-portal-navigation",
  standalone: true,
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  imports: [
    RouterModule,
    TuiHostedDropdownModule,
    TranslocoModule,
    TuiButtonModule,
    TuiCheckboxModule,
    NavigationSettingsComponent,
    ActionButtonComponent,
    BurgerMenuComponent
  ]
})
export class NavigationComponent {
  @Input() basicNavigation = true;
  private authService = inject(AuthService);
  private router = inject(Router);
  private themeSwitcher = inject(ThemeSwitcherService);
  private profileService = inject(ProfileService);
  private i18nService = inject(I18nService);
  private walletConnectService = inject(WalletConnectService);
  private subscriptionService = inject(SubscriptionService);
  private unleashToggleService = inject(UnleashToggleService);

  @HostBinding("style.visibility") private get isVisibile() {
    return this.authService.currentUser() !== undefined && this.i18nService.translationLoaded() ? "visible" : "hidden";
  }

  user = this.authService.currentUser;
  isLoggedIn = toSignal(this.authService.getLoggedInSubject().asObservable(), { initialValue: false });
  isAvatarMenuOpen = false;
  organization = computed(() => this.profileService.organizations()?.[0]);
  isEnterprise = computed(() => this.subscriptionService.activeSubscription()?.plan === ConsumptionPlan.enterprise);
  isWalletConnected = this.walletConnectService.isConnected;
  walletAddress = computed(() => {
    const address = this.walletConnectService.address();
    return address?.substring(0, 6) + "..." + address?.substring(address.length - 4);
  });
  walletIcon = this.walletConnectService.walletIcon;
  isAdmin = computed(
    () => this.authService.currentUser() && hasRole(Role.Admin, this.authService.currentUser()!.scopes)
  );
  isSuperAdmin = computed(() => this.authService.currentUser()?.scopes.includes(Scopes.SuperAdminRead));

  isManageUsersActive = toSignal(this.unleashToggleService.getFeature$("multipleUsers"));
  isSamlActive = toSignal(this.unleashToggleService.getFeature$("saml"));

  async logout() {
    this.isAvatarMenuOpen = false;
    await this.authService.logout();
    this.router.navigate(["/login"]);
  }

  support() {
    this.isAvatarMenuOpen = false;
    this.router.navigate(["/support"]);
  }
  profile() {
    this.isAvatarMenuOpen = false;
    this.router.navigate(["/profile"]);
  }

  manageSubscriptions() {
    this.isAvatarMenuOpen = false;
    this.router.navigate(["/manage-subscriptions"]);
  }

  toggleTheme(mode: ThemeMode) {
    this.themeSwitcher.toggleMode(mode);
  }

  connectWallet() {
    this.walletConnectService.open();
  }

  disconnectWallet() {
    this.walletConnectService.disconnect();
  }

  openWallet() {
    this.walletConnectService.open();
  }
}
