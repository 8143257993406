<div
  class="oneinch-text-regular-13 tablet:flex-row desktop:py-8 tablet:py-6 tablet:px-20 tablet:gap-6 tablet:justify-start flex flex-col items-center justify-center gap-4 p-4"
>
  <span class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
    >© {{ currentYear }} 1inch Limited</span
  >
  <div class="flex gap-4">
    <span
      class="border-day-border-border-2ry dark:border-night-border-border-2ry text-day-static-brand dark:text-night-static-brand cursor-pointer border-l pl-4"
    >
      <transloco [key]="'footer.privacyPolicy'" [params]="legalUrls"></transloco>
    </span>
    <span
      class="tablet:border-day-border-border-2ry tablet:dark:border-night-border-border-2ry tablet:border-l text-day-static-brand dark:text-night-static-brand tablet:pl-4 cursor-pointer"
    >
      <transloco [key]="'footer.termsOfService'" [params]="legalUrls"></transloco>
    </span>
    <span
      class="border-day-border-border-2ry dark:border-night-border-border-2ry text-day-static-brand dark:text-night-static-brand cursor-pointer border-l pl-4"
    >
      <transloco [key]="'footer.commercialTerms'" [params]="legalUrls"></transloco>
    </span>
  </div>
</div>
