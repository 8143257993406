@if (user()?.setupCompleted && !organization()?.isSuspended) {
  <div
    class="tablet:px-4 tablet:py-3 desktop:px-8 desktop:py-5 text-day-text-icons-main dark:text-night-text-icons-main oneinch-text-regular-16 desktop:h-[5.5rem] tablet:h-[4.5rem] flex h-[3.5rem] items-center justify-between space-x-8 p-3"
  >
    <a routerLink="/dashboard" data-test-id="navigationHome">
      <svg class="tablet:hidden h-8 w-32">
        <use xlink:href="/assets/logos/logo-mobile.svg#img" />
      </svg>
      <svg class="tablet:block desktop:hidden hidden h-12 w-44">
        <use xlink:href="/assets/logos/logo-tablet.svg#img" />
      </svg>
      <svg class="desktop:block text-day-special-logo-main dark:text-night-special-logo-main hidden h-12 w-64">
        <use xlink:href="/assets/logos/logo-desktop.svg#img" />
      </svg>
    </a>

    <div class="desktop:flex desktop:items-center hidden flex-1 gap-8 py-2">
      <div class="hover:opacity-75">
        <a
          routerLink="/dashboard"
          data-test-id="navigationDashboard"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
          class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
        >
          {{ "navigation.items.dashboard" | transloco }}
        </a>
      </div>
      <div class="hover:opacity-75">
        <a
          routerLink="/applications"
          data-test-id="navigationApplications"
          routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
          class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
        >
          {{ "navigation.items.applications" | transloco }}
        </a>
      </div>
      <div class="hover:opacity-75" data-test-id="navigationStatistics">
        <a
          routerLink="/statistics"
          routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
          [routerLinkActiveOptions]="{ exact: true }"
          class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
        >
          {{ "navigation.items.stats" | transloco }}
        </a>
      </div>

      <div class="hover:opacity-75">
        <a
          routerLink="/documentation"
          data-test-id="navigationDocumentation"
          routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
          class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
        >
          {{ "navigation.items.docs" | transloco }}
        </a>
      </div>
      <div class="hover:opacity-75">
        <a
          routerLink="/pricing"
          data-test-id="navigationPricing"
          routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
          class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
        >
          {{ "navigation.items.pricing" | transloco }}
        </a>
      </div>
    </div>

    <div class="flex items-center gap-6">
      <dev-portal-action-button
        (click)="support()"
        class="desktop:block text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500 hover:oneinch-bg-hover-main-gray hidden"
        icon="/assets/icons/help.svg#img"
      ></dev-portal-action-button>
      <dev-portal-navigation-settings></dev-portal-navigation-settings>
      <dev-portal-burger-menu
        [isLoggedIn]="user()?.setupCompleted!"
        [canUpgrade]="!isEnterprise()"
        class="desktop:hidden"
      ></dev-portal-burger-menu>

      <tui-hosted-dropdown [content]="avatarMenu" [(open)]="isAvatarMenuOpen" data-test-id="avatarMenu">
        <div class="flex flex-col items-start">
          <div
            class="gradient oneinch-bg-day-static-main-gradient dark:oneinch-bg-night-static-main-gradient cursor-pointer rounded-full"
          >
            <svg class="tablet:w-10 tablet:h-10 h-8 w-8">
              <use href="/assets/1inch-profile-icon.svg#img" />
            </svg>
          </div>
        </div>
      </tui-hosted-dropdown>
    </div>
  </div>
} @else {
  <div
    class="tablet:px-4 tablet:py-3 desktop:px-8 desktop:py-5 text-day-text-icons-main dark:text-night-text-icons-main oneinch-text-regular-16 flex h-[5.5rem] items-center justify-between p-3"
  >
    <div class="flex items-center justify-start gap-16">
      <svg class="tablet:hidden h-8 w-32">
        <use xlink:href="/assets/logos/logo-mobile.svg#img" />
      </svg>
      <svg class="tablet:block desktop:hidden hidden h-12 w-44">
        <use xlink:href="/assets/logos/logo-tablet.svg#img" />
      </svg>
      <svg class="desktop:block text-day-special-logo-main dark:text-night-special-logo-main hidden h-12 w-64">
        <use xlink:href="/assets/logos/logo-desktop.svg#img" />
      </svg>
      <div class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500 desktop:flex hidden">
        <a
          routerLink="/documentation"
          data-test-id="navigationDocumentation"
          routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
          class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
        >
          {{ "navigation.items.docs" | transloco }}
        </a>
      </div>
    </div>
    <div class="flex-end flex items-center gap-2">
      <dev-portal-action-button
        (click)="support()"
        class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500 hover:oneinch-bg-hover-main-gray tablet:flex hidden"
        icon="/assets/icons/help.svg#img"
      ></dev-portal-action-button>
      <dev-portal-navigation-settings class="border-0 p-1"></dev-portal-navigation-settings>
      <dev-portal-burger-menu [isLoggedIn]="false" class="desktop:hidden" [canUpgrade]="true"></dev-portal-burger-menu>
      @if (!user()?.id) {
        <div class="oneinch-text-regular-16 desktop:flex hidden hover:opacity-75" data-test-id="navigationSignIn">
          <a
            routerLink="/login"
            routerLinkActive="!text-day-static-brand !dark:text-night-static-brand"
            [routerLinkActiveOptions]="{ exact: true }"
            class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500"
          >
            {{ "navigation.items.login" | transloco }}
          </a>
        </div>
      }
      @if (isLoggedIn()) {
        <div class="flex cursor-pointer gap-3 p-2" (click)="logout()">
          <svg class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500 h-6 w-6">
            <use xlink:href="/assets/icons/disconnect.svg#img" />
          </svg>
        </div>
      }
    </div>
  </div>
}

<ng-template #avatarMenu>
  <div
    class="oneinch-text-regular-16 bg-day-bg-bg-main dark:bg-night-bg-bg-main text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500 flex min-w-[12.5rem] flex-col items-start justify-center gap-[2px] !rounded-xl p-2"
  >
    @if (isWalletConnected()) {
      <div
        class="bg-day-bg-bg-2ry dark:bg-night-bg-bg-2ry text-day-text-icons-main dark:text-night-text-icons-main oneinch-text-regular-16 flex w-full cursor-pointer flex-row justify-center gap-3 rounded-lg border-0 px-2 py-[0.44rem]"
        (click)="openWallet()"
      >
        @if (walletIcon()) {
          <img [src]="walletIcon()" class="w-6" alt="wallet icon" />
        }
        <span>{{ walletAddress() }}</span>
        <svg
          class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500 hover:text-day-static-primary-blue dark:hover:text-night-static-primary-blue h-6 w-6 cursor-pointer self-end"
          (click)="disconnectWallet()"
        >
          <use xlink:href="/assets/icons/link_24.svg#img"></use>
        </svg>
      </div>
    } @else {
      <button tuiButton appearance="button-info" size="l" [icon]="walletIcon" (click)="connectWallet()">
        {{ "navigation.items.walletConnect" | transloco }}
      </button>
      <ng-template #walletIcon>
        <svg class="text-day-static-primary-blue dark:text-night-static-primary-blue h-6 w-6">
          <use xlink:href="/assets/icons/wallet_24.svg#img"></use>
        </svg>
      </ng-template>
    }

    <div
      class="hover:dark:bg-night-bg-bg-2ry hover:bg-day-bg-bg-2ry flex cursor-pointer gap-3 self-stretch rounded-md p-2"
      (click)="profile()"
      data-test-id="profileMenu"
    >
      <svg class="h-6 w-6">
        <use xlink:href="/assets/icons/profile.svg#img" />
      </svg>
      <span class="hover:text-day-text-icons-main dark:hover:text-night-text-icons-main">
        {{ "navigation.items.profile" | transloco }}
      </span>
    </div>
    @if (isAdmin() && (isManageUsersActive() || isSamlActive())) {
      <div
        class="hover:dark:bg-night-bg-bg-2ry hover:bg-day-bg-bg-2ry flex cursor-pointer gap-3 self-stretch p-2"
        routerLink="/settings"
        data-test-id="settingsMenu"
      >
        <svg class="h-6 w-6">
          <use xlink:href="/assets/icons/settings.svg#img" />
        </svg>
        <span class="hover:text-day-text-icons-main dark:hover:text-night-text-icons-main">
          {{ "navigation.items.settings" | transloco }}
        </span>
      </div>
    }
    <div
      class="hover:dark:bg-night-bg-bg-2ry hover:bg-day-bg-bg-2ry flex cursor-pointer gap-3 self-stretch rounded-md p-2"
      (click)="manageSubscriptions()"
      data-test-id="manageSubscriptions"
    >
      <svg class="h-6 w-6">
        <use xlink:href="/assets/icons/card.svg#img" />
      </svg>
      <span class="hover:text-day-text-icons-main dark:hover:text-night-text-icons-main">
        {{ "navigation.items.manageSubscription" | transloco }}
      </span>
    </div>
    <div
      class="hover:dark:bg-night-bg-bg-2ry hover:bg-day-bg-bg-2ry flex cursor-pointer gap-3 self-stretch p-2"
      (click)="logout()"
    >
      <svg class="h-6 w-6">
        <use xlink:href="/assets/icons/disconnect.svg#img" />
      </svg>
      <span class="hover:text-day-text-icons-main dark:hover:text-night-text-icons-main rounded-md">
        {{ "navigation.items.logout" | transloco }}
      </span>
    </div>
    @if (isSuperAdmin()) {
      <div
        class="hover:dark:bg-night-bg-bg-2ry hover:bg-day-bg-bg-2ry flex cursor-pointer gap-3 self-stretch p-2"
        routerLink="/admin"
      >
        <span class="hover:text-day-text-icons-main dark:hover:text-night-text-icons-main">
          {{ "navigation.items.admin" | transloco }}
        </span>
      </div>
    }
  </div>
</ng-template>
